.container {
  padding: 50px 30px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 30px;
  background-color: #ccc;
  background-position: bottom;
  
  /* min-height: 85vh; */
}

.heading {
  margin: 0 auto;
  max-width: 500px;
  color: #904157;
  font-size: 3.1rem;
  line-height: 4.1rem;
  font-weight: bold;
  font-family: 'Monotype Corsiva';
  letter-spacing: 1px;
}

.heading span {
  background-clip: text;
  background-image: linear-gradient(to right, #18bb9c, #2e4f49);
  color: transparent;
}

.right img {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .right img {
    width: 100%;
  }
  .heading {
    font-size: 2rem;
    line-height: 2rem;
  }

}

@media only screen and (max-width: 950px) {
  
  .heading {
    font-size: 2rem;
    line-height: 2rem;
  }

}
