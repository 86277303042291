.navigationContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #351602;
    position: fixed;
    width: 100%;
}

.navLink a {
    font-size: 25px;
    color: #fff;
    text-decoration: none;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    border-radius:10px;
    padding: 5px;
    cursor: pointer;
    outline: none;
    background-color: #2b3a34;
    border: none;
    -webkit-box-shadow: 0 0 10px #fff;
    box-shadow: 0 0 10px #fff;
}

.navLink a:hover {
    color: #904157;
    background-color: #18BB9C;
}

.navLink a:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

.navImg img {
    width: 230px;
}

@media only screen and (max-width: 600px) {
    
    .navLink a {
        font-size: 15px;
        text-align: center;
    }

    .navImg img {
        width: 120px;
    }
}