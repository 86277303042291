.footerContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #351602;
    padding-top: 15px;
    padding-bottom: 15px;
}

.footerText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 200px;
    color: #fff;
    font-size: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.footerImg {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.footerImg img {
    max-width: 50px;
}

.footerImg a {
    color: #FFB6C1;
    text-decoration: none;
}

.footerImg a:hover {
    color: #ccc;
}

.footerBottom ul li {
    color: #18bb9c;
}

.footerBottom ul li a {
    color: #FFB6C1;
    text-decoration: none;
}

.footerBottom ul li a:hover {
    color: #ccc;
}

@media only screen and (max-width: 600px) {
    .footerText {
        font-size: 15px;
        max-width: 120px;
    }

    .footerImg {
        max-width: 120px;
    }
}