.container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding-top: 40px;
}

.heading {
  color: #904157;
  font-weight: 500;
  font-size: 2.1rem;
  text-align: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.toolbar {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
}

.colors {
  display: flex;
  gap: 20px;
  padding: 0 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.colors .color {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #239ce2;
}
.colors .active {
  border: 2px solid #000;
}

/*--------- template ---------*/
.templates {
  display: flex;
  gap: 15px;
  padding: 0 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.templates .template {
  height: 150px;
  width: 106.25px;
  border: 2px solid #ccc;
  background-color: #239ce2;
}
/* .templates .active {
  border: 2px solid #000 !important;
} */

.templates .active {
  border: 4px solid rgb(252, 2, 2) !important;
}

.templates .template:nth-child(1) {
  background: url('../../../public/images/templates/Template_1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.templates .template:nth-child(2) {
  background: url('../../../public/images/templates/Template_2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.templates .template:nth-child(3) {
  background: url('../../../public/images/templates/Template_3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.templates .template:nth-child(4) {
  background: url('../../../public/images/templates/Template_4.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.templates .template:nth-child(5) {
  background: url('../../../public/images/templates/Template_5.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
.templates .template:nth-child(6) {
  background: url('../../../public/images/templates/Template_6.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
.templates .template:nth-child(7) {
  background: url('../../../public/images/templates/Template_7.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
.templates .template:nth-child(8) {
  background: url('../../../public/images/templates/Template_8.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
.templates .template:nth-child(9) {
  background: url('../../../public/images/templates/Template_9.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.templates .template:nth-child(10) {
  background: url('../../../public/images/templates/Template_10.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.templates .template:nth-child(11) {
  background: url('../../../public/images/templates/Template_11.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
/*-------- ------------ */

.toolbar button {
  padding: 8px 16px;
  border-radius: 5px;
  background-color: #239ce2;
  color: #fff;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 1px;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}

.toolbar button svg {
  height: 20px;
  width: 20px;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}
